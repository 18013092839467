import React, { useEffect, useState } from "react";
import * as style from "./style";

export type ButtonType = {
  title: string;
  onclick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  color?: string;
  url?: string;
  disabled?: boolean;
};

const Button = ({
  title,
  onclick,
  color,
  url,
  disabled = false,
}: ButtonType) => {
  return (
    <button
      className={
        color === "white"
          ? style.buttonWhite
          : color === "parents"
          ? style.buttonParents
          : style.button
      }
      title={title}
      onClick={onclick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default Button;
