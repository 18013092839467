import { useGetLastUserElectricityDataQuery } from "../../services/graphql/enhanceApi";

export const useHasUserElectricityData = (
  consentApplicationResultId: number | undefined | null,
  isSkip: boolean
) => {
  const skip = isSkip || !consentApplicationResultId;
  const result = useGetLastUserElectricityDataQuery(
    {
      consentApplicationResultId: consentApplicationResultId || 0,
    },
    {
      skip: skip,
      refetchOnMountOrArgChange: true,
    }
  );
  const data = result.data?.zerocame_view_user_electricity_data;
  return {
    // 取得中かどうか
    isFetching: result.isFetching,
    // 結果 (電力データが存在するかどうか)
    result: !!data && 0 < data.length,
  };
};
