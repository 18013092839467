import React, { useEffect, useState } from "react";
import * as styles from "./style";

// 毎回ホームを読み込む際に​再計算されるようなアニメーション

const ActionScore = (props: { number: number }) => {
  const [movePx, setMovePx] = useState<number[]>([]);
  const { number } = props;
  const numStr = String(number).split("");
  const numberArr: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  useEffect(() => {
    const pxArr = numStr.map((num) =>
      Number((numberArr.indexOf(Number(num)) + 1) * 56)
    );
    setMovePx(pxArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      {numStr.map((num, index) => {
        return (
          <div className={styles.border} key={index}>
            <div
              className={styles.number}
              style={{ transform: `translateY(-${movePx[index]}px)` }}
            >
              <p className={styles.paragraph}>0</p>
              {numberArr.map((number, index: number) => {
                return (
                  <p key={index} className={styles.paragraph}>
                    {number}
                  </p>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ActionScore;
