import { css } from "@emotion/css";

export const container = css`
  display: flex;
  justify-content: center;
`;

export const border = css`
  position: relative;
  height: 56px;
  width: 28px;
  overflow: hidden;
`;

export const number = css`
  position: absolute;
  top: 0;
  width: 28px;
  transition: all;
  transition-duration: 1000ms;
`;

export const paragraph = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 56px;
`;
