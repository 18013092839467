/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC, useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import { HambugerMenuText } from "../../atoms/HambugerMenuText";
import React from "react";
import { LogoutHandler } from "../../templates/Home";

const MENU_WIDTH = 225;

type Props = {
  profileHandler: () => void;
  infoPowerLinkHandler: () => void;
  introductionHandler: () => void;
  tutorialHandler: () => void;
  isVisibleGreenStatus: boolean;
  faqHandler: () => void;
  enquiryHandler: () => void;
  contractHandler: () => void;
  privacyHandler: () => void;
  logout: LogoutHandler;
  onClickHamburger: () => void;
};

export const HamburgerMenu: FC<Props> = (props) => {
  const ref = useRef(null);

  const [initialX, setInitialX] = useState(400);
  const [animateX, setAnimateX] = useState(134);

  const closeHamburger = () => {
    setInitialX(134);
    setAnimateX(400);
    setTimeout(() => {
      props.onClickHamburger();
    }, 500);
  };

  useOutsideClick(ref, () => closeHamburger());
  return (
    <>
      {/*{props.isVisibleGreenStatus && <Tutorial onComplete={props.onComplete} />}*/}
      {props.isVisibleGreenStatus === false && (
        <div className={container}>
          <motion.div
            initial={{ x: initialX }}
            animate={{ x: animateX }}
            transition={{
              delay: 0.2,
              bounce: false,
              default: { ease: "linear" },
            }}
          >
            <section
              className={boxStyle}
              ref={props.logout.logoutState === false ? ref : undefined}
            >
              <div className={blankBoxStyle}>
                <img
                  className={imgStyle}
                  src={`/img/close.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
                  onClick={closeHamburger}
                />
              </div>
              <HambugerMenuText
                text={"プロフィール"}
                onClick={props.profileHandler}
              />
              <hr className={hrStyle}></hr>
              <HambugerMenuText
                text={"電力データ連携関連情報"}
                onClick={props.infoPowerLinkHandler}
              />
              <hr className={hrStyle}></hr>
              <HambugerMenuText
                text={"友だち紹介"}
                onClick={props.introductionHandler}
              />
              <hr className={hrStyle}></hr>
              <HambugerMenuText
                text={"チュートリアル"}
                onClick={props.tutorialHandler}
              />
              <hr className={hrStyle}></hr>
              <HambugerMenuText
                text={"FAQ"}
                onClick={props.faqHandler}
                iconSrc="/img/outer-Link-checkpoint.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}"
              />
              <hr className={hrStyle}></hr>
              <HambugerMenuText
                text={"お問合せ先"}
                onClick={props.enquiryHandler}
              />
              <hr className={hrStyle}></hr>
              <HambugerMenuText
                text={"利用規約"}
                onClick={props.contractHandler}
                iconSrc="/img/outer-Link-checkpoint.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}"
              />
              <hr className={hrStyle}></hr>
              <HambugerMenuText
                text={"個人情報取得同意書"}
                onClick={props.privacyHandler}
                iconSrc="/img/outer-Link-checkpoint.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}"
              />
              <div className={blankBoxStyleMiddle}></div>
              <HambugerMenuText
                text={"ログアウト"}
                onClick={props.logout.onClickLogout}
                signOut="/img/signOut.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}"
              />
              <div className={blankBoxStyleEnd}></div>
            </section>
          </motion.div>
        </div>
      )}
    </>
  );
};

const boxStyle = css`
  width: 241px;
  height: 100vh;
  background-color: #fff;
  z-index: 15;
  position: relative;
  overflow: auto;
`;

const container = css`
  position: fixed;
  right: 0;
  z-index: 70;
  right: 0;
  width: 375px;
`;

const blankBoxStyle = css`
  height: 64px;
  padding-top: 10px;
  padding-left: 16px;
  background-color: #f4f7f7;
`;
const blankBoxStyleMiddle = css`
  height: 32px;
  background-color: #f4f7f7;
`;
const blankBoxStyleEnd = css`
  height: 138px;
  padding-top: 10px;
  padding-left: 16px;
  background-color: #f4f7f7;
`;
const imgStyle = css`
  margin-right: 150px;
  margin-top: 20px;
  width: 24px;
  height: 24px;
  right: 0;
  top: 0;
  position: fixed;
`;
const hrStyle = css`
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 30px;
  background: #eaeaea;
  height: 1px;
  border: 0;
`;
