import { css } from "@emotion/css";
import { FC, useCallback, useState } from "react";
import { mediaQuery } from "../../../utils/ui";

export type button = {
  title: string;
  onclick: Function;
  isAlert?: boolean;
};

type Props = {
  cancel?: button;
  proceed: button;
};

export const PopupFooter: FC<Props> = ({ cancel, proceed }) => {
  const [isDisabled, setisDisabled] = useState(false);

  // 2回目以降のクリックでonclickを呼ばない
  const onceClick = useCallback(() => {
    if (!isDisabled) {
      proceed.onclick();
      setisDisabled(true);
    } else {
    }
  }, [isDisabled, setisDisabled, proceed]);

  return (
    <div className={divStyle}>
      {cancel && (
        <span
          className={spanCancelStyle}
          onClick={() => cancel && cancel.onclick()}
        >
          {cancel.title}
        </span>
      )}
      <span
        className={
          cancel
            ? spanProceedStyle(!!proceed.isAlert)
            : spanProceedOnlyStyle(!!proceed.isAlert)
        }
        onClick={() => onceClick()}
      >
        {proceed.title}
      </span>
    </div>
  );
};

const divStyle = css`
  width: 283px;
  height: 60px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const spanCancelStyle = css`
  width: 142px;
  height: 60px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 2px #eaeaea;
  border-right: solid 2px #eaeaea;
  color: #1e5343;
`;
const spanProceedStyle = (isAlert: boolean) => css`
  width: 141px;
  height: 60px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 2px #eaeaea;
  color: ${isAlert ? "#F200CA" : "#01b64e"};
`;
const spanProceedOnlyStyle = (isAlert: boolean) => css`
  ${spanProceedStyle(isAlert)};
  width: 300px;
`;
