import { useRouter } from "next/router";
import { useCallback } from "react";
import { TabbarHandlers } from "../../components/molucules/Tabbar";

export const useTabbarHandlersCreator = (): TabbarHandlers => {
  const router = useRouter();

  // ヘッダーホーム押下時の遷移
  const home = useCallback(() => {
    window.location.href = "/";
  }, []);

  const action = useCallback(() => {
    window.location.href = "/action/list";
  }, []);

  const mission = useCallback(() => {
    window.location.href = "/mission";
  }, []);

  const electricity = useCallback(() => {
    window.location.href = "/electricity";
  }, []);

  return {
    home,
    action,
    mission,
    electricity,
  };
};
