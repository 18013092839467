import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

// import { UserRegist } from "../components/templates/UserRegist";
import { checkError } from "../utils/error";
import { useErrorSetting } from "../utils/hooks/useErrorSetting";
import { useGreenActionAuth0 } from "../utils/hooks/useGreenActionAuth0";
import {
  useGetNowQuery,
  useGetUserActionCountByStatusQuery,
  useGetUserGreenscoreReviewLast2Query,
  useGetUserGreenscoreLatestQuery,
  useGetMyDataQuery,
  useGetGreenscoreDetailQuery,
  useGetGreenscoreCo2EmissionQuery,
} from "../services/graphql/enhanceApi";
import Loading from "../components/organisms/Loading";
import { openCommonApp, path } from "../utils/ui/route";
import {
  initTopPageState,
  setActionListCurrentTab,
  setDoneActionReview,
} from "../services/greenscore/slice";
import {
  Home,
  ModalState,
  RetrospectiveState,
  ScoreItem,
} from "../components/templates/Home";
import { GreenscoreActionCategory } from "../services/cms/greenscoreAction/types";
import { useGetGreenscoreActionsQuery } from "../services/cms/greenscoreAction/";
import { useAppDispatch } from "../services/store";
import { DateObjectUnits, DateTime } from "luxon";
import {
  hasAlreadyReviewed,
  isShowLinkageError,
  saveAlreadyReviewed,
  saveShowLinkageError,
} from "../utils/ui/greenscore";
import { useTabbarHandlersCreator } from "../utils/hooks/useTabbarHandlersCreator";
import {
  GetUserGreenscoreReviewLast2Query,
  GetUserGreenscoreLatestQuery,
  GetGreenscoreDetailQuery,
} from "../services/graphql/enhanceApi";
import { useGetIsDoneActionReview } from "../services/greenscore/selectors";
import { setOnboardingPage } from "../services/onboarding/slice";
import { useOnboardingState } from "../services/onboarding/selectors";
import { useHasUserElectricityData } from "../utils/electricity";

const actionCategory: GreenscoreActionCategory[] = [
  "節電",
  "電気",
  "買い物",
  "食",
  "資源",
  "移動",
];

type ScoreSummary = {
  category: GreenscoreActionCategory;
  scoreSum?: number;
};
/**
 * 振り返りのリセットが実行される時間
 */
const RESET_TIME: DateObjectUnits = {
  hour: 4,
  minute: 0,
  second: 0,
  millisecond: 0,
};

const GreenScoreHome: FC = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const TabbarHandlers = useTabbarHandlersCreator();
  TabbarHandlers.electricity = () => {
    // 電力データ連携始める画面へ遷移
    openCommonApp(path.electricity);
  };
  /**
   * 画面制御に関する変数の管理
   */
  const [
    {
      workingOnAction,
      retrospectiveState,
      modalState,
      isDoneActionReview,
      isCurrentScoreShowable,
    },
    setPageState,
  ] = useState<{
    workingOnAction?: number; // 取組み中のアクションの数
    retrospectiveState: RetrospectiveState | "notReady";
    modalState: ModalState | "notReady";
    isDoneActionReview: boolean; // 振り返り完了からの遷移の制御
    isCurrentScoreShowable: boolean; // 現在スコアを表示するか
  }>({
    retrospectiveState: "notReady",
    modalState: "notReady",
    isDoneActionReview: useGetIsDoneActionReview(),
    isCurrentScoreShowable: true,
  });

  const [hamburger, setHamburger] = useState(false);

  // ログアウトモーダルの表示制御
  const [logoutModalState, setLogoutModalState] = useState(false);

  // データ連係失敗エラーの表示要否
  const [showLinkageError, setShowLinkageError] = useState(
    isShowLinkageError()
  );
  useEffect(() => {
    // 振り返り完了からの遷移フラグを戻す
    // dispatch(setDoneActionReview(false));
    dispatch(initTopPageState());
  }, [dispatch]);

  // 認証処理
  const authState = useGreenActionAuth0();
  // API取得のスキップ条件を記載
  const isNotDoneAuth0 =
    !authState.isAccessTokenReady || !authState.auth0UserId;

  const { auth0UserId } = useGreenActionAuth0();
  //同意申込結果取得
  const { data: consentApplicationResult, isFetching: isGetMyDataFetching } =
    useGetMyDataQuery(
      { auth0_user_id: auth0UserId || "" },
      {
        refetchOnMountOrArgChange: true,
        skip:
          !auth0UserId ||
          authState.userData?.consentApplicationStatus !== "resultReceived",
      }
    );

  const userConsentApplicationResult =
    consentApplicationResult?.zerocame_fn_current_consent_application_result[0];
  const hasUserElectricityData = useHasUserElectricityData(
    userConsentApplicationResult?.id,
    !auth0UserId ||
      authState.userData?.consentApplicationStatus !== "resultReceived"
  );

  // エラー発生をチェック
  const errorState = checkError({
    auth0Error: authState.isError ? authState.error : undefined,
    auth0UserId: authState.auth0UserId,
  });
  // エラー制御
  useErrorSetting(errorState);

  /**
   * CMSの行動定義を取得（行動中のアクションがない場合のみ）
   */
  const {
    data: cmsActions,
    isSuccess: isGetCmsActionsSuccess,
    isError: isGetCmsActionsError,
  } = useGetGreenscoreActionsQuery();

  // ユーザーアクション実施状況（取組み中・習慣化済み）を取得する
  const {
    currentData: getUserActionCount,
    isSuccess: isGetUserActionCountSuccess,
    error: getUserActionCountError,
  } = useGetUserActionCountByStatusQuery(
    // クエリの引数を記載
    { auth0UserId: authState.auth0UserId as string },
    // skip状態条件を記載
    { skip: isNotDoneAuth0, refetchOnMountOrArgChange: true } // アクセストークン取得済の場合のみリクエスト
  );

  // ユーザーの振り返りの直近２回分を取得する
  const {
    data: getUserGreenscoreReviewLast2,
    isSuccess: isGetUserGreenscoreReviewLast2Success,
    error: getUserGreenscoreReviewLast2Error,
  } = useGetUserGreenscoreReviewLast2Query(
    // クエリの引数を記載
    {
      auth0UserId: authState.auth0UserId as string,
    },
    // skip状態条件を記載
    { skip: isNotDoneAuth0, refetchOnMountOrArgChange: true } // アクセストークン取得済の場合のみリクエスト
  );

  const greenscoreIdReviewLast2 =
    getUserGreenscoreReviewLast2 &&
    getUserGreenscoreReviewLast2.zerocame_greenscore_action_review.length > 1 &&
    getUserGreenscoreReviewLast2.zerocame_greenscore_action_review[1].greenscore
      ?.id
      ? getUserGreenscoreReviewLast2.zerocame_greenscore_action_review[1]
          .greenscore?.id
      : undefined;
  const { data: greenscoreReviewLast2Detail } = useGetGreenscoreDetail(
    greenscoreIdReviewLast2
  );

  //現在日時取得
  const {
    data: viewNows,
    isSuccess: isGetViewNowSuccess,
    error: getNowError,
  } = useGetNowQuery(
    {},
    { skip: !authState.isAccessTokenReady, refetchOnMountOrArgChange: true } // アクセストークン取得済の場合のみリクエスト
  );

  const now = viewNows?.zerocame_view_now[0].epoch;

  /**
   * 最後のグリーンスコアを取得する
   */
  const {
    data: getUserGreenscoreLatestQuery,
    isSuccess: isGetUserGreenscoreLatestQuerySuccess,
    isError: isGetUserGreenscoreLatestQueryError,
  } = useGetUserGreenscoreLatestQuery(
    {
      auth0UserId: authState.auth0UserId as string,
    },
    {
      skip: isNotDoneAuth0, // 取組み中アクションがある場合のみ
      refetchOnMountOrArgChange: true,
    }
  );

  const greenscoreIdLatest =
    getUserGreenscoreLatestQuery &&
    getUserGreenscoreLatestQuery.zerocame_greenscore.length > 0 &&
    getUserGreenscoreLatestQuery.zerocame_greenscore[0].id
      ? getUserGreenscoreLatestQuery.zerocame_greenscore[0].id
      : undefined;
  const { data: greenscoreLatestDetail } =
    useGetGreenscoreDetail(greenscoreIdLatest);

  /**
   * 前回振り返り時のグリーンスコアを算出する
   */
  const prevScoreSum = useMemo<number | undefined>(() => {
    if (isGetUserGreenscoreReviewLast2Success) {
      if (
        getUserGreenscoreReviewLast2?.zerocame_greenscore_action_review &&
        getUserGreenscoreReviewLast2.zerocame_greenscore_action_review
          .length === 2 &&
        getUserGreenscoreReviewLast2.zerocame_greenscore_action_review[1]
          .greenscore
      ) {
        const lastReviewScore =
          getUserGreenscoreReviewLast2.zerocame_greenscore_action_review[1]
            .greenscore.score_sum;

        return lastReviewScore;
      }
    }
  }, [
    isGetUserGreenscoreReviewLast2Success,
    getUserGreenscoreReviewLast2?.zerocame_greenscore_action_review,
  ]);

  // 振り返りボタンを押下時の処理
  const reviewHandler = useCallback(() => {
    saveAlreadyReviewed();
    router.push(path.greenscore.retroepective);
  }, [router]);

  // カテゴリボタンを押下時の処理
  /*const categoryHandler = useCallback(
    (category: GreenscoreActionCategory) => {
      dispatch(setActionListCurrentTab(category));
      router.push(path.greenscore.actionTop);
    },
    [router, dispatch]
  );*/

  /**
   * 累計CO2排出量を取得する
   */
  const {
    data: getGreenscoreCo2EmissionQuery,
    isSuccess: isGetGreenscoreCo2EmissionQuerySuccess,
    isError: isGetGreenscoreCo2EmissionQueryError,
  } = useGetGreenscoreCo2EmissionQuery(
    {
      auth0UserId: authState.auth0UserId as string,
    },
    {
      skip: isNotDoneAuth0, // 取組み中アクションがある場合のみ
      refetchOnMountOrArgChange: true,
    }
  );

  const sumCo2Emissions =
    getGreenscoreCo2EmissionQuery &&
    getGreenscoreCo2EmissionQuery.sum_Co2_Emissions &&
    getGreenscoreCo2EmissionQuery.sum_Co2_Emissions.aggregate?.sum
      ?.total_co2_emission_reduction_dr
      ? getGreenscoreCo2EmissionQuery.sum_Co2_Emissions.aggregate?.sum
          .total_co2_emission_reduction_dr
      : 0;

  /**
   * データ連携失敗モーダル表示要否
   */
  const isShowDataLinkageErrorModal = useMemo(() => {
    // console.log("islink ----------", showLinkageError);
    // console.log("authState ----------", authState.userData?.consentApplicationStatus);
    if (
      showLinkageError &&
      authState.userData?.consentApplicationStatus == "cancelByTransfer"
    ) {
      return true;
    }
    return false;
  }, [authState.userData?.consentApplicationStatus, showLinkageError]);

  /**
   * モーダル表示と、振り返り遷移ボタン表示の判定
   */
  useEffect(() => {
    // すべて習慣化済みか判定
    if (
      isGetCmsActionsSuccess &&
      cmsActions &&
      isGetUserActionCountSuccess &&
      getUserActionCount
    ) {
      if (
        cmsActions.contents.length ==
        getUserActionCount.habitual_actions.aggregate?.count
      ) {
        //console.log("ptn1----------");
        // すべて習慣化済み
        setPageState({
          workingOnAction: 0,
          retrospectiveState: "doneAll",
          modalState: isShowDataLinkageErrorModal
            ? "ngAfterDataLinkage"
            : "none",
          isDoneActionReview,
          isCurrentScoreShowable: true,
        });
        // 振り返り対象アクション有無判定
      } else if (isGetUserActionCountSuccess && getUserActionCount) {
        if (
          !getUserActionCount.active_acions.aggregate ||
          getUserActionCount.active_acions.aggregate.count === 0
        ) {
          //console.log("ptn2----------");
          // 振り返り対象アクションなし
          setPageState({
            workingOnAction: 0,
            retrospectiveState: "noAction",
            modalState: "noActionModal",
            isDoneActionReview,
            isCurrentScoreShowable: true,
          });
        } else if (typeof now === "number") {
          // 振り返り対象アクションあり
          if (
            !hasAlreadyReviewed() ||
            !isReviewReset(
              now,
              isGetUserGreenscoreReviewLast2Success
                ? getUserGreenscoreReviewLast2
                : undefined
            )
          ) {
            //console.log("ptn3----------", !hasAlreadyReviewed());
            // 初回振り返り前　または　リセット後ではない
            setPageState({
              workingOnAction: getUserActionCount.active_acions.aggregate.count,
              retrospectiveState: "available",
              modalState: !hasAlreadyReviewed()
                ? "firstRetrospective"
                : isShowDataLinkageErrorModal
                ? "ngAfterDataLinkage"
                : "none",
              isDoneActionReview,
              isCurrentScoreShowable: hasAlreadyReviewed(),
            });
          } else if (isShowDataLinkageErrorModal) {
            // 未連携モーダル表示
            //console.log("not link----------");
            setPageState({
              workingOnAction: getUserActionCount.active_acions.aggregate.count,
              retrospectiveState: "available",
              modalState: "ngAfterDataLinkage",
              isDoneActionReview,
              isCurrentScoreShowable: hasAlreadyReviewed(),
            });
          } else {
            // 初回振り返り前ではない　かつ　リセット後
            setPageState({
              workingOnAction: getUserActionCount.active_acions.aggregate.count,
              retrospectiveState: "available",
              modalState: "resetRetrospective",
              isDoneActionReview,
              isCurrentScoreShowable: false,
            });
          }
        } else if (isShowDataLinkageErrorModal) {
          // 未連携モーダル表示
          //console.log("not link2----------");
          setPageState({
            workingOnAction: getUserActionCount.active_acions.aggregate.count,
            retrospectiveState: "available",
            modalState: "ngAfterDataLinkage",
            isDoneActionReview,
            isCurrentScoreShowable: hasAlreadyReviewed(),
          });
        }
      }
    }
  }, [
    isGetCmsActionsSuccess,
    isGetUserActionCountSuccess,
    cmsActions,
    getUserActionCount,
    now,
    isGetUserGreenscoreReviewLast2Success,
    getUserGreenscoreReviewLast2,
    workingOnAction,
    isDoneActionReview,
    isShowDataLinkageErrorModal,
  ]);

  /**
   * 前回の各カテゴリのスコア集計
   */
  const beforeCategoryScore = useMemo<ScoreSummary[]>(() => {
    if (greenscoreReviewLast2Detail) {
      return createScoreArray({
        zerocame_fn_greenscore_detail_by_category:
          greenscoreReviewLast2Detail.zerocame_fn_greenscore_detail_by_category,
      });
    }
    return createScoreArray(undefined);
  }, [greenscoreReviewLast2Detail]);

  /**
   * 直近のスコアを整形
   */
  const categoryScore = useMemo<ScoreSummary[] | undefined>(() => {
    if (greenscoreLatestDetail) {
      return createScoreArray({
        zerocame_fn_greenscore_detail_by_category:
          greenscoreLatestDetail.zerocame_fn_greenscore_detail_by_category,
      });
    }
    return; // 最新のスコア集計は存在する前提なので、データ取得できるまで表示は待たせる
  }, [greenscoreLatestDetail]);

  const isNotLinked =
    authState.userData?.consentApplicationStatus !== "resultReceived" ||
    consentApplicationResult?.zerocame_fn_current_consent_application_result[0]
      .consent_application_status !== "1" ||
    !hasUserElectricityData.result;
  //TODO: 同意申し込み審査結果待ちの場合を含むかどうかによって条件追加が必要

  const onClickHamburger = () => {
    const _isShowTabModal = modalState === "noActionModal";
    const _isFirstRetro = modalState === "firstRetrospective";
    const _isReset = modalState === "resetRetrospective";
    if (_isShowTabModal || _isFirstRetro || _isReset) {
      return;
    }
    setHamburger(!hamburger);
  };

  const onClickTutorialHandler = () => {
    dispatch(setOnboardingPage(true));
    router.push(path.tutorial);
  };
  const onClickLogout = () => {
    setLogoutModalState(true);
  };

  const onClickCancelLogout = () => {
    setLogoutModalState(false);
  };

  // ログイン画面に遷移関数
  const onClickLogoutHandler = useCallback(() => {
    // authState.logoutFunction();
    router.push(path.logout);
  }, [router]);

  const onClickScoreElectricityHelp = useCallback(() => {
    router.push(path.aboutElectricity);
  }, [router]);

  const onClickScoreHelp = useCallback(() => {
    router.push(path.infoGreenScorePage);
  }, [router]);

  /**
   * カテゴリを選択した際に実行される関数
   */
  const onClickCategory = useCallback(
    (category: GreenscoreActionCategory) => {
      // 電気カテゴリはイベント無効
      if (category == "電気") {
        return;
      }

      // 選択中のタブを指定する
      dispatch(setActionListCurrentTab(category));
      router.push(path.greenscore.actionSelect);
    },
    [dispatch, router]
  );

  const closeModal = useCallback(() => {
    // if (isFirstVisit) {
    //   saveAlreadyShowinghome();
    //   setPageState({
    //     retrospectiveState,
    //     workingOnAction,
    //     hasReviewed: false,
    //   });
    // }
    //console.log("closeModal--------------");
    if (isShowDataLinkageErrorModal && modalState != "ngAfterDataLinkage") {
      // 未連携モーダル表示
      // console.log("next not link----------");
      setPageState({
        workingOnAction,
        retrospectiveState,
        modalState: "ngAfterDataLinkage",
        isDoneActionReview,
        isCurrentScoreShowable,
      });
    } else {
      if (modalState == "ngAfterDataLinkage") {
        // 連係失敗モーダル再表示抑制（１日１回のみ表示制約）
        saveShowLinkageError();
      }
      //console.log("close--------------");
      setPageState({
        workingOnAction,
        retrospectiveState,
        modalState: "none",
        isDoneActionReview,
        isCurrentScoreShowable,
      });
    }
  }, [
    modalState,
    workingOnAction,
    retrospectiveState,
    isDoneActionReview,
    isCurrentScoreShowable,
    isShowDataLinkageErrorModal,
  ]);

  // データがそろっていない時にローディング画面を表示
  if (
    !authState ||
    !authState.isAuthenticated ||
    !now ||
    // !scoreDiff ||
    (sumCo2Emissions !== 0 && !sumCo2Emissions) ||
    typeof workingOnAction !== "number" ||
    !categoryScore ||
    // !beforeCategoryScore ||
    !isGetUserGreenscoreReviewLast2Success ||
    retrospectiveState === "notReady" ||
    modalState === "notReady" ||
    isGetMyDataFetching ||
    hasUserElectricityData.isFetching
  ) {
    return <Loading isColor={true} />;
  }

  return (
    <>
      <Home
        // 表示期間
        nowDate={new Date(now * 1000)}
        // 前回振り返り合計スコアと最新振り返り合計スコアの差
        prevScoreSum={prevScoreSum}
        // 各カテゴリの情報
        score={categoryScore.map<ScoreItem>((categoryScore, index) => {
          let value: number | undefined = categoryScore.scoreSum;
          let diff: number | undefined;
          if (
            beforeCategoryScore[index].scoreSum &&
            categoryScore.scoreSum === undefined
          ) {
            value = 0;
          }
          if (
            typeof value === "number" &&
            beforeCategoryScore.length > index &&
            typeof beforeCategoryScore[index].scoreSum === "number"
          ) {
            diff = value - (beforeCategoryScore[index].scoreSum || 0);
          }
          return {
            category: categoryScore.category,
            value: value,
            diff: diff,
          };
        })}
        // 現在スコアの表示可否
        isCurrentScoreShowable={isCurrentScoreShowable}
        // 累計CO2排出量（最新振り返りの合計Co2）
        co2Reduction={sumCo2Emissions}
        // 取組み中のアクション数
        workingOnAction={workingOnAction}
        // ハンバーガーメニューのクリック挙動
        onClickHamburger={onClickHamburger}
        // ヘルプのクリック挙動
        onClickScoreHelp={onClickScoreHelp}
        // デンキカテゴリヘルプのクリック挙動
        onClickScoreElectricityHelp={onClickScoreElectricityHelp}
        // 振り返るのクリック挙動
        onClickLookBackButton={() => reviewHandler()}
        onClickDataLinkageInfo={() => {
          openCommonApp(path.powerLinkageInfo);
        }}
        onClickCategory={onClickCategory}
        onClickModalClose={closeModal}
        modalState={modalState}
        retrospectiveState={retrospectiveState}
        tabbarHandlers={TabbarHandlers}
        // スナックバー表示制御 true：表示 false；非表示
        snackbarState={isDoneActionReview}
        // スナックバー確認のクリック挙動
        snackbarConfClick={() => {
          router.push(path.greenscore.actionTop);
        }}
        hamburger={hamburger}
        onClicktutorial={onClickTutorialHandler}
        // ハンバーガーメニューのログアウトクリック挙動
        logout={{
          logoutState: logoutModalState,
          onClickLogout: () => onClickLogout(),
          onClickCancelLogout: () => onClickCancelLogout(),
          onClickLogoutHandler: () => onClickLogoutHandler(),
        }}
        //TODO: 連携情報
        isNotLinked={isNotLinked}
      />
    </>
  );
};

export default GreenScoreHome;

/**
 * カテゴリごとにスコアを配列に格納
 * @param score DBから取得した値を指定
 * @param time 最新のスコアか前回のスコアかを指定（最新ならば0、前回ならば1を指定）
 * @returns GreenscoreActionCategoryで指定されている順にカテゴリとスコアを配列として格納
 */
const createScoreArray = (
  score:
    | Pick<
        GetGreenscoreDetailQuery,
        "zerocame_fn_greenscore_detail_by_category"
      >
    | undefined
) => {
  let scoreArray: ScoreSummary[] = [];
  actionCategory.forEach((category) => {
    const categoryScore = {
      category: category,
      scoreSum: undefined,
    } as ScoreSummary;

    const categoryScoreSummary =
      score?.zerocame_fn_greenscore_detail_by_category.find(
        (detailByCategory) => detailByCategory.category_name == category
      );
    if (
      categoryScoreSummary &&
      typeof categoryScoreSummary.score === "number"
    ) {
      categoryScore.scoreSum = categoryScoreSummary.score;
    }

    scoreArray.push(categoryScore);
  });
  return scoreArray;
};
/**
 * 振り返りリセット状態か判定する
 * @param now
 * @param getUserGreenscoreReviewLast2
 * @returns
 */
const isReviewReset = (
  now: number,
  getUserGreenscoreReviewLast2?: GetUserGreenscoreReviewLast2Query
) => {
  let isReviewReset = false;
  if (
    getUserGreenscoreReviewLast2 &&
    getUserGreenscoreReviewLast2.zerocame_greenscore_action_review.length > 0 &&
    getUserGreenscoreReviewLast2.zerocame_greenscore_action_review[0].created_at
  ) {
    const current = DateTime.fromSeconds(now);
    const resetTime =
      current.hour >= 4
        ? current.set(RESET_TIME)
        : current.minus({ day: 1 }).set(RESET_TIME);
    const lastReviewTime = DateTime.fromISO(
      getUserGreenscoreReviewLast2.zerocame_greenscore_action_review[0]
        .created_at
    );
    isReviewReset = resetTime > lastReviewTime;
  }
  return isReviewReset;
};
/**
 * グリーンスコアのカテゴリごとのスコア合計を取得する
 * @param greenscoreId
 * @returns
 */
const useGetGreenscoreDetail = (
  greenscoreId?: number
): { data?: GetGreenscoreDetailQuery; error: any } => {
  const { data, isSuccess, isError, error } = useGetGreenscoreDetailQuery(
    { greenscore_id: greenscoreId || 0 },
    {
      refetchOnMountOrArgChange: true,
      skip: !greenscoreId,
    }
  );
  return {
    data: isSuccess && data ? data : undefined,
    error: isError && error ? error : undefined,
  };
};
