import { FC, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import { modalBaseStyle } from "../../templates/ActionTopModals";
import { PopupBody } from "../PopupBody";

export type Props = {
  title: string;
  message: string;
  cancelButton: {
    label: string;
    onClick: () => void;
  };
  okButton: {
    label: string;
    onClick: () => void;
  };
};

export const ConfirmModal: FC<Props> = (props) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => props.cancelButton.onClick());

  return (
    <AnimatePresence>
      <motion.div
        key="modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={modalBaseStyle}
      >
        <div ref={ref}>
          <PopupBody
            message={props.title}
            text={props.message}
            cancel={{
              title: props.cancelButton.label,
              onclick: props.cancelButton.onClick,
            }}
            proceed={{
              title: props.okButton.label,
              onclick: props.okButton.onClick,
              isAlert: true,
            }}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
