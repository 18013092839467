import { DateTime } from "luxon";

const HAS_REVIEWED_KEY = "Greenscore-Has-Reviewed";
const SHOW_LINKAGE_ERROR = "Show-Linkage-Error";
/**
 * ホーム画面を表示したことがある場合、trueを返却する
 * @returns
 */
export const hasAlreadyReviewed = (): boolean => {
  if (typeof window !== "undefined" && window.localStorage) {
    return !!window.localStorage.getItem(HAS_REVIEWED_KEY);
  }
  return true;
};
/**
 *  ホーム画面を表示したことを保存する
 */
export const saveAlreadyReviewed = () => {
  if (typeof window !== "undefined" && window.localStorage)
    window.localStorage.setItem(HAS_REVIEWED_KEY, "" + new Date().getTime());
};

export const isShowLinkageError = (): boolean => {
  if (typeof window !== "undefined" && window.localStorage) {
    const showLinkageErrorTime =
      window.localStorage.getItem(SHOW_LINKAGE_ERROR);
    if (showLinkageErrorTime) {
      const resetTime = DateTime.now()
        .setZone("UTC+9")
        .set({ hour: 4, minute: 0, second: 0 });
      const prevTime = DateTime.fromMillis(Number(showLinkageErrorTime));
      return resetTime > prevTime;
    }
  }
  return true;
};

export const saveShowLinkageError = () => {
  if (typeof window !== "undefined" && window.localStorage)
    window.localStorage.setItem(SHOW_LINKAGE_ERROR, "" + new Date().getTime());
};
