/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";

type Props = {
  text: string;
  onClick: () => void;
  iconSrc?: string;
  signOut?: string;
};

export const HambugerMenuText: FC<Props> = ({
  text = "",
  onClick,
  iconSrc,
  signOut,
}) => {
  return (
    <div className={boxStyle} onClick={onClick}>
      <img className={signOut ? signOutStyle : undefined} src={signOut} />
      <span className={textStyle}>{text}</span>
      <img className={iconSrc ? imgStyle : undefined} src={iconSrc} />
    </div>
  );
};

const boxStyle = css`
  width: 225px;
  height: 56px;
  padding-top: 18px;
  padding-left: 30px;
  padding-right: 18px;
  padding-bottom: 17px;
  background-color: #fff;
  display: flex;
  cursor: pointer;
`;

const textStyle = css`
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #1e5343;
  white-space: pre-wrap;
`;

const imgStyle = css`
  margin-top: 1px;
  margin-left: 4px;
  width: 16px;
  height: 16px;
`;

const signOutStyle = css`
  margin-top: -1px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
`;
