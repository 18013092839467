/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import { css } from "@emotion/css";
import React, { useState, FC, useCallback, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { mediaQuery } from "../../../../utils/ui";
import Button from "../../../molucules/associateComfirm/Button";

export type Props = {
  isHome?: boolean;
  onCloseClick?: Function;
  onButtonClick: Function;
  onGoHome: Function;
};

const imgDir = "/img/associationModal/";

export const LinkageAfterFailModal: FC<Props> = (props) => {
  const onCloseClick = props.onCloseClick;
  const onButtonClick = props.onButtonClick;
  const onGoHome = props.onGoHome;

  //ボタン押下後の動作
  const buttonClick = () => {
    onButtonClick();
  };

  //ホームに遷移
  const goHome = () => {
    onGoHome();
  };

  return (
    <AnimatePresence>
      <motion.div
        key="modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={modalBaseStyle}
        onClick={() => props.isHome && onCloseClick && onCloseClick()}
      >
        <div id="modal" className={mainStyle}>
          <article className={boxStyle}>
            {props.isHome && onCloseClick && (
              <img
                className={cancel}
                onClick={(event) => {
                  event.stopPropagation();
                  onCloseClick();
                }}
                src={`${imgDir}cancel.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
              />
            )}
            <img
              className={warningIcon}
              src={`${imgDir}cautionMark.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
              alt=""
            />
            <div className={mainText}>
              <p>電気使用量の情報が</p>
              <p>連携できませんでした</p>
            </div>
            <div className={subText}>
              <p>こちらをご確認ください。</p>
            </div>
            <div className={buttonContainer}>
              <Button title={"電力データ連携関連情報"} onclick={buttonClick} />
            </div>
            {!props.isHome && (
              <a
                className={link}
                onClick={goHome}
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  className={arrowleft}
                  src={`${imgDir}leftChevron.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
                />
                <span className={linkSubText}>ホームに戻る</span>
              </a>
            )}
          </article>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const frame_width = `331px`;

export const modalBaseStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100%;
  background: rgba(30, 83, 67, 0.5);
  ${mediaQuery.portrait} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 100%;
    background: rgba(30, 83, 67, 0.5);
  }
  ${mediaQuery.landscape} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 100%;
    background: rgba(30, 83, 67, 0.5);
  }
`;

const addImgQuery = (url: string) =>
  `${url}?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`;

const boxStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 331px;
  max-height: 95%;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 50, 69, 0.3);
  background: url(${addImgQuery(`${imgDir}background.png`)});
  background-size: contain;
  background-color: #fff;
  background-repeat: no-repeat;
  padding: 43px 24px 0;
  ${mediaQuery.portrait} {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 480px;
    max-height: 90%;
    border-radius: 16px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 56px;
    box-shadow: 0 3px 6px 0 rgba(0, 50, 69, 0.3);
    /* background: url(${addImgQuery(
      "/img/materNotLinkedModal/background.png"
    )}); */
    background-size: contain;
    background-color: #fff;
    background-repeat: no-repeat;
  }
  ${mediaQuery.landscape} {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 640px;
    max-height: 90%;
    border-radius: 16px;
    padding-top: 24px;
    padding-right: 22px;
    padding-left: 22px;
    padding-bottom: 56px;
    box-shadow: 0 3px 6px 0 rgba(0, 50, 69, 0.3);
    /* background: url(${addImgQuery(
      "/img/materNotLinkedModal/background.png"
    )}); */
    background-size: contain;
    background-color: #fff;
    background-repeat: no-repeat;
  }
`;

const cancel = css`
  position: absolute;
  top: 24px;
  right: 16px;
`;

const warningIcon = css`
  margin-bottom: 32px;
`;

const mainStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const mainText = css`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 32px;
  line-height: 32px;
  text-align: center;
  color: #1e5343;
`;

const subText = css`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 32px;
  line-height: 20px;
  text-align: center;
  color: #1e5343;
`;

const linkSubText = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1e5343;
`;

const text = css`
  font-weight: 300;
  font-size: 12px;
  margin-top: -18px;
  margin-bottom: 32px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #1e5343;
`;

const link = css`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const arrowleft = css`
  margin-right: 4px;
  margin-bottom: 1px;
`;

const boxLinkIcon = css`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

const buttonContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
