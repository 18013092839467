import { css } from "@emotion/css";
// import { AnimatePresence, motion } from "framer-motion";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { ActionCompleteModal } from "../../organisms/ActionCompleteModal";
import { GetBadgeModal } from "../../organisms/GetBadgeContents";
import { checkActionType } from "../../../utils/ui/greenAction";

export type ModalType =
  | "None" // 非表示
  | "ActionDetail" // アクション詳細
  | "ActionComplete" // アクション完了
  | "Bingo" // ビンゴ達成
  | "Badge" // バッジ獲得
  | "BingoPre"; // ビンゴ達成パターン表示（モーダルとしては表示されない）

// export type BingoAction = ActionContent & {
//   isDone: boolean;
// };

// export type Props = {
//   modalType: ModalType;
//   actionDetailContent?: BingoAction;
//   actionDetailHandler: () => void;
//   topPageHandler: () => void;
//   closeHandler: () => void;
//   isEnableAction: boolean;
//   badgeInfo: BadgeGetInfo;
// };

// export const ActionTopModals: FC<Props> = ({
//   modalType = "None",
//   actionDetailContent,
//   actionDetailHandler,
//   topPageHandler,
//   closeHandler,
//   isEnableAction,
//   badgeInfo,
// }) => {
//   // アクション詳細モーダルの表示条件
//   const isOpenActionDetail =
//     modalType === "ActionDetail" && !!actionDetailContent;
//   // アクション完了モーダルの表示条件
//   const isOpenActionComplete = modalType === "ActionComplete";
//   const isBingo = modalType === "Bingo";
//   const isBadge = modalType === "Badge";
//   return (
//     <AnimatePresence>
//       {isOpenActionDetail && (
//         <motion.div
//           key="modal"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//           className={modalBaseStyle}
//         >
//           <ActionDetailModal
//             image={actionDetailContent.icon}
//             title={actionDetailContent.title}
//             text={actionDetailContent.text}
//             tipsTitle={actionDetailContent.tipsTitle}
//             tipsText={actionDetailContent.tipsText}
//             actionDetailHandler={actionDetailHandler}
//             closeHandler={closeHandler}
//             actionStatus={
//               actionDetailContent.isDone
//                 ? "done"
//                 : isEnableAction
//                 ? "enable"
//                 : "disable"
//             }
//             actionType={checkActionType(actionDetailContent.actionType[0])}
//           />
//         </motion.div>
//       )}
//       {isOpenActionComplete && (
//         <motion.div
//           key="modal"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//           className={modalBaseStyle}
//         >
//           <ActionCompleteModal closeHandler={closeHandler} />
//         </motion.div>
//       )}
//       {isBingo && (
//         <motion.div
//           key="modal"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//           className={modalBaseStyle}
//         >
//           <ActionBingoModal
//             closeHandler={closeHandler}
//             currentBadgeInfo={badgeInfo}
//           />
//         </motion.div>
//       )}
//       {isBadge && (
//         <motion.div
//           key="modal"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//           className={modalBaseStyle}
//         >
//           <GetBadgeModal
//             closeHandler={closeHandler}
//             current={badgeInfo.getCount}
//             topPageHandler={topPageHandler}
//           />
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// };
export const modalBaseStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100%;
  background-color: rgba(30, 83, 67, 0.5);
  ${mediaQuery.portrait} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 100%;
    background-color: rgba(30, 83, 67, 0.5);
  }
  ${mediaQuery.landscape} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 100%;
    background-color: rgba(30, 83, 67, 0.5);
  }
`;
