import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";

type Props = {
  message: string;
  text?: string;
};

export const PopupHeader: FC<Props> = ({ message = "", text = "" }) => {
  return (
    <div className={divStyle}>
      <span className={spanStyle}>{message}</span>
      <span className={textStyle}>{text}</span>
    </div>
  );
};

const divStyle = css`
  width: 283px;
  height: 132px;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

const spanStyle = css`
  width: 251px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  color: #1e5343;
`;

const textStyle = css`
  width: 252px;
  height: 36px;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  color: #1e5343;
`;
