import { css } from "@emotion/css";
import { tabbarHeight } from "../../molucules/Tabbar";

export const main = css`
  height: 100svh;
  min-height: 100svh;
  margin: 0 auto;
  background-image: url("/img/templates/home/background.png");
  background-position: center top;
  background-size: cover;
  position: relative;
`;
export const hamburgerBack = css`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 83, 67, 0.5);
  z-index: 25;
`;

export const header = css`
  display: flex;
  padding: 9px 16px;
  height: 48px;
  /* border-bottom: solid 1px rgba(255, 255, 255, 0.25); */
  align-items: center;
`;
export const logo = css`
  flex: 1;
`;
export const logoImage = css`
  width: 119px;
  height: 30px;
  background-image: url("/img/templates/home/header/logo.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const hamburger = css`
  width: 48px;
  height: 48px;
  background-image: url("/img/templates/home/header/hamburger.svg");
  background-position: center center;
  background-repeat: no-repeat;
`;

export const content = css`
  width: ${process.env.NEXT_PUBLIC_DEFAULT_PAGE_WIDTH};
  margin: 0 auto;
`;

export const scoreTopBlock = css`
  position: relative;
  min-height: 187px;
  /* ヘッダー:48,タイトル＆日付:39,カテゴリ全て:135,Co2削減：48、振り返りエリア:76,footer:50,graphtopmargin:13, 調整：49*/
  height: calc(
    100svh - (48px + 39px + 135px + 48px + 76px + 50px + 13px - 49px)
  );
  padding: 0px 16px;
`;
export const scoreTitle = css`
  display: flex;
`;
export const scoreTitleText = css`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  margin-right: 4px;
`;
export const scoreTitleHelp = css`
  width: 16px;
  height: 16px;
  background-image: url("/img/templates/home/help.svg");
  background-position: center center;
  background-repeat: no-repeat;
`;
export const graphBlockArea = css`
  position: absolute;
  margin-top: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const graphBlock = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* ヘッダー:48,タイトル＆日付:39,カテゴリ全て:135,Co2削減：48、振り返りエリア:76,footer:50,graphtopmargin:13, 調整：10*/
  height: calc(
    100svh - (48px + 39px + 135px + 48px + 76px + 50px + 13px - 10px)
  );
  /* min-height: 187px; */
`;
export const dateRange = css`
  margin-top: 8px;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
`;
export const graphCenterBlock = css`
  position: absolute;
  width: 100%;
  /* 上下左右中央 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const totalScore = css`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 57px;
  color: #ffffff;
`;
export const prevDiffBlock = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
`;

export const prevDiffValueBlock = css`
  ${prevDiffBlock}
  margin-top: 8px;
`;
export const prevDiffLabel = css`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
`;
export const prevValue = css`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

export const circleGraph = (width: number) => css`
  /* position: absolute;
  top: 4px;
  left: calc(50% - ${width / 2}px); */
  display: flex;
  justify-content: center;
  width: ${width}px;
`;

export const messageBalloon = css`
  position: absolute;
  top: 153px;
  left: calc(50% - 95.5px);
  width: 191px;
  height: 75px;
  padding: 26px 11px 9px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  background-image: url("/img/templates/home/messageBalloon.svg");
  background-position: center center;
  background-repeat: no-repeat;
`;

export const graphBalloon = css`
  position: absolute;
  width: 68px;
  height: 55px;
  padding-top: 2px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e5343;
  background-image: url("/img/templates/home/balloon.svg");
  background-position: center center;
  background-repeat: no-repeat;
`;

export const scoreBottomBlock = css`
  border-style: solid;
  border-width: 1px 0 0;
  border-color: rgba(255, 255, 255, 0.25);
`;
export const scoreBottomBlockRow = css`
  display: flex;
  height: 45px;
`;

export const scoreBottomItem = css`
  height: 45px;
  display: flex;
  flex: 1;
  align-items: center;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: rgba(255, 255, 255, 0.25);
  padding: 8px 16px;

  &:first-child {
    border-right-width: 1px;
  }
`;
export const scoreBottommostItem = css`
  display: flex;
  flex: 1;
  align-items: center;
  border-style: solid;
  border-width: 0 0 0;
  border-color: rgba(255, 255, 255, 0.25);
  padding: 8px 16px;

  &:first-child {
    border-right-width: 1px;
  }
`;

export const scoreColorSample = (color: string) => css`
  width: 4px;
  height: 12px;
  background: ${color};
  border-radius: 2px;
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

export const scoreLabel = css`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-left: 8px;
`;
export const scoreValue = css`
  margin-right: 8px;
  flex: 1;
  text-align: right;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

export const scoreValueText = css`
  height: 17px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
`;

export const scoreDiffFrame = (
  graphdiff: boolean,
  isNoLinkageElectricity: boolean
) => css`
  box-sizing: border-box;
  width: ${isNoLinkageElectricity === true ? "45px" : "38px"};
  height: 16px;
  margin: ${graphdiff === true ? "none" : "0 0 0 auto"};
  border: ${graphdiff === true ? "1px solid rgba(255, 255, 255, 0.5)" : "none"};
  border-radius: 4px;
`;

export const scoreDiffLine = (graphdiff: boolean) => css`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  line-height: 10px;
  text-align: ${graphdiff === true ? "center" : "none"};
`;

export const scoreDiffArrow = css`
  display: inline-block;
  margin-right: 2px;
`;

export const scoreDiff = css`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
`;
export const scoreDiffUp = css`
  ${scoreDiff}
  background-image: url("/img/templates/home/scoreBottom/up.svg");
`;
export const scoreDiffEven = css`
  ${scoreDiff}
  padding-right: 7px;
  background-image: url("/img/templates/home/scoreBottom/even.svg");
`;
export const scoreDiffDown = css`
  ${scoreDiff}
`;
export const notLinked = css`
  height: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
`;
export const scoreElectricityHelp = css`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  background-image: url("/img/templates/home/help.svg");
  background-position: center center;
  background-repeat: no-repeat;
`;

export const co2ReductionCard = css`
  height: 45px;
  padding-top: 14px;
  background: rgba(30, 83, 67, 0.1);
`;
export const co2ReductionBlock = css`
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
`;
export const co2ReductionLabel = css`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
`;
export const co2ReductionAmount = css`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #ffffff;
`;

export const co2ReductionAmountUnit = css`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #ffffff;
  margin-left: 4px;
`;

const fadeInAnime = css`
  animation-name: fade-in-modal;
  animation-duration: 300ms;
  @keyframes fade-in-modal {
    0% {
      opacity: 0%;
    }
    100% {
    }
  }
`;
export const fadeOutAnime = css`
  animation-name: fade-out-modal;
  animation-duration: 350ms;
  @keyframes fade-out-modal {
    0% {
    }
    100% {
      opacity: 0%;
    }
  }
`;

export const workingOnActionCardBackground = css`
  height: 76px;
  background: rgba(30, 83, 67, 0.1);
`;

export const workingOnActionCardBase = css`
  ${fadeInAnime}
  width: 100vw;
  height: 76px;
`;

export const workingOnActionCard = css`
  ${workingOnActionCardBase}
  padding: 16px 0;
  background: #f2f2f2;
  border-radius: 24px 24px 0px 0px;
`;

export const buttonActionCard = css`
  ${workingOnActionCardBase}
  margin-top: 14px;
  padding: 24px 0;
  border-radius: 24px 24px 0px 0px;
`;

export const workingOnActionCardOverModal = css`
  ${workingOnActionCardBase}
  position: absolute;
  left: 0;
  bottom: ${tabbarHeight}px;
  padding-top: calc(15px - 4px);
  z-index: 75;
`;
// export const workingOnActionBlock = css`
//   display: flex;
//   justify-content: space-between;
//   padding: 0 30px 0 29px;
// `;
// export const workingOnActionLabel = css`
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 21px;
//   color: #1e5343;
// `;
// export const workingOnActionAmount = css`
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 21px;
//   color: #1e5343;
// `;
export const lookBackButton = css`
  width: 283px;
  height: 44px;
  margin: 0 auto 0;
  padding: 10px 0;
  background: linear-gradient(90deg, #1e5343 0%, #359d88 100%);
  border-radius: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`;

export const noActionMessage = css`
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  width: 230px;
  height: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(72, 72, 65, 0.5);
`;

export const doneAllActionMessage = css`
  width: 243px;
  height: 20px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(72, 72, 65, 0.5);
`;

export const modalBase = css`
  ${fadeInAnime}
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(30, 83, 67, 0.5);
`;

export const lookBackButtonOverModal = css`
  padding: 10px 16px;
  width: 283px;
  height: 44px;
  background: linear-gradient(90deg, #1e5343 0%, #359d88 100%);
  border-radius: 22px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
`;
export const lookBackButtonOverModalBorder = (
  isResetRetrospective: boolean
) => css`
  width: 291px;
  height: 52px;
  padding: 4px;
  margin: 0 auto 0;
  background: ${isResetRetrospective ? "none" : "#d9d9d9"};
  border-radius: 27.5px;
`;

export const tooltipOverModal = css`
  position: absolute;
  top: calc(-8px - 81px - 9px);
  /* left: calc(calc(calc(254px + 10px + 10px) / 2 * -1) + 94px + 94px / 2); */
  left: calc(100% / 2 - calc(259px + 10px + 10px) / 2);
  width: calc(259px + 10px + 10px);
  height: calc(81px + 9px + 9px);
  background-image: url(/img/templates/home/tooltip.svg);

  padding-top: calc(0px + 10px); //13->0px
  padding-left: calc(0px + 10px); //20->0px
  padding-right: calc(40px + 10px);

  align-items: center;

  color: #1e5343;
`;

export const tooltipText = css`
  width: 214px;
  height: 64px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 12px;
  padding-left: 12px;
`;

export const tooltipClosebutton = css`
  position: absolute;
  top: calc(16px + 9px);
  right: calc(12px + 10px);
  width: 32px;
  height: 32px;
  background-image: url(/img/templates/home/close-modal.svg);
`;

export const snackbar = css`
  display: flex;
  align-items: flex-start;
  background: var(--surface-01, #fff);
  border-radius: 8px;
`;

export const snackbarTexts = css`
  display: flex;
  width: 267px;
  padding: 16px 0px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-right: 1px solid var(--line-05, #e1e1e1);
`;

export const snackbarTitle = css`
  display: flex;
  width: 225px;
  flex-direction: column;
  color: var(--text-02, #1e5343);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const snackbarText = css`
  display: flex;
  width: 225px;
  flex-direction: column;
  color: var(--text-02, #1e5343);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.42px;
`;

export const snackbarRight = css`
  display: flex;
  padding: 40px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const snackbarComfirm = css`
  color: var(--text-02, #1e5343);
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;
