import { css } from "@emotion/css";
import { FC, useCallback, useState, useRef, useMemo } from "react";
import { GreenscoreActionCategory } from "../../../services/cms/greenscoreAction/types";

export type Data = {
  value: number;
  color: string;
  category: GreenscoreActionCategory;
};

export type Props = {
  width: number;
  height: number;
  strokeWidth: number;
  data: Data[];
  // onInitialized?: Function;
};

export type OnInitializedContextData = {
  value: number;
  color: string;
  offset: number;
  length: number;
};
export type OnInitializedContext = {
  radius: number;
  roundLength: number;
  data: OnInitializedContextData[];
};

type TooltipProps = {
  category?: GreenscoreActionCategory;
  position?: { top: number; left: number };
};

export const CircleGraph: FC<Props> = (props) => {
  const [tooltipState, setTooltipState] = useState<TooltipProps>();
  const boxRef = useRef<HTMLDivElement>(null);

  // const onInitializedContext = {
  //   radius: radius,
  //   roundLength: roundLength,
  //   data: [],
  // } as OnInitializedContext;

  const nodeList = useMemo<JSX.Element[]>(() => {
    const total = (() => {
      let total = 0;
      props.data.forEach((item) => {
        total += item.value;
      });
      return total;
    })();

    // 半径
    const radius = (props.width - props.strokeWidth) / 2;
    // 円周の長さ
    const roundLength = radius * 2 * Math.PI;

    let offset = 0;
    const nodeList: JSX.Element[] = [];
    props.data.forEach((item, index) => {
      const length = (roundLength * item.value) / total;
      const anime = `anime-${index}`;

      // onInitializedContext.data.push({
      //   value: item.value,
      //   color: item.color,
      //   offset: offset,
      //   length: length,
      // });
      const circleStyle = css`
        animation-name: ${anime};
        animation-duration: 1000ms;
        stroke: ${item.color};
        stroke-dasharray: ${length}, ${roundLength};
        stroke-dashoffset: -${offset};
        @keyframes ${anime} {
          0% {
            stroke-dasharray: ${length}, none;
            stroke-dashoffset: ${length};
          }
          100% {
            stroke-dasharray: ${length}, ${roundLength};
            stroke-dashoffset: -${offset};
          }
        }
      `;
      const onClickCategory = (
        e: React.MouseEvent<SVGCircleElement, MouseEvent>,
        category: GreenscoreActionCategory
      ) => {
        let top = 0;
        let left = 0;
        if (boxRef.current) {
          const { top: boxTop, left: BoxLeft } =
            boxRef.current.getBoundingClientRect();
          top = boxTop;
          left = BoxLeft;
        }
        setTooltipState({
          category,
          // ボックス左上の位置から相対（＋画像サイズ調整）位置に表示する
          position: {
            top: e.clientY - top - BALLOON_POSITION_COLLECT.top,
            left: e.clientX - left - BALLOON_POSITION_COLLECT.left,
          },
        });
      };

      nodeList.push(
        <circle
          className={circleStyle}
          key={index}
          cx="50%"
          cy="50%"
          r={radius}
          onClick={(e) => {
            if(item.category !== "スコア0"){
              onClickCategory(e, item.category);
            }
          }}
        ></circle>
      );
      offset += length;
    });
    return nodeList.reverse();
  }, [props.data, props.strokeWidth, props.width]);

  // if (props.onInitialized) {
  //   props.onInitialized(onInitializedContext);
  // }

  return (
    <div style={{ position: "relative" }} ref={boxRef}>
      <svg className={svgStyle(props.width, props.height, props.strokeWidth)}>
        {nodeList}
      </svg>
      <Tooltip
        category={tooltipState?.category}
        position={tooltipState?.position}
      />
    </div>
  );
};

const svgStyle = (width: number, height: number, strokeWidth: number) => css`
  position: relative;
  width: ${width}px;
  height: ${height}px;
  transform: rotate(-90deg);

  circle {
    position: relative;
    fill: none;
    stroke-width: ${strokeWidth};
    stroke-linecap: round;
  }
`;

const Tooltip: FC<TooltipProps> = ({ category, position }) => (
  <>
    {category && (
      <div className={graphBalloon(position?.top, position?.left)}>
        {category}
      </div>
    )}
  </>
);
/**
 * 吹き出しの下突起部分をクリック位置に合わせるための位置調整
 * 吹き出し画像が変更になった場合は、併せて変更する
 */
const BALLOON_POSITION_COLLECT = {
  top: 35,
  left: 35,
} as const;

export const graphBalloon = (top = 0, left = 0) => css`
  position: absolute;
  top: ${top}px;
  left: ${left}px;
  width: 68px;
  height: 55px;
  padding-top: 2px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e5343;
  background-image: url("/img/templates/home/balloon.svg");
  background-position: center center;
  background-repeat: no-repeat;
`;
