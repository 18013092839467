/* eslint-disable @next/next/no-img-element */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as style from "./style";
import { CircleGraph, Data } from "../../atoms/CircleGraph";
import { GreenscoreActionCategory } from "../../../services/cms/greenscoreAction/types";
import {
  Tabbar,
  TabbarActionHighlightLayer,
  TabbarHandlers,
} from "../../molucules/Tabbar";
import ActionScore from "../../molucules/ActionScore";
import { HamburgerMenu } from "../../organisms/HamburgerMenu";
import { LinkageAfterFailModal } from "../../organisms/associationModals/LinkageAfterFailModal";
import { useRouter } from "next/router";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import { ConfirmModal } from "../../molucules/ConfirmModal";
import { labels } from "../../../utils/ui";
import { openCommonApp, path } from "../../../utils/ui/route";

export type BallonContext = {
  isShow: boolean;
  top: number;
  left: number;
  text: string;
};

export type ScoreItem = {
  category: GreenscoreActionCategory;
  value?: number;
  diff?: number;
  // color: string;
  // onClick: (category: GreenscoreActionCategory) => void;
};

export type ModalState =
  | "none" // モーダル表示なし
  | "ngAfterDataLinkage" // データ連係後のNG
  | "noActionModal" // 振り返り対象アクションなし
  | "firstRetrospective" // 初回振り返りモーダル
  | "resetRetrospective"; // 振り返りリセット状態モーダル

export type RetrospectiveState =
  | "noAction" // 取組み中アクションなし
  | "doneAll" // 全アクション習慣化済み
  | "available"; // 振り返り実施可能

export type LogoutHandler = {
  logoutState: boolean;
  onClickLogout: () => void;
  onClickCancelLogout: () => void;
  onClickLogoutHandler: () => void;
};

export type Props = {
  nowDate: Date;
  prevScoreSum?: number;
  // TODO:templateで文言を指定、表示判定を渡すようにする
  // showingMessage: boolean;
  score: ScoreItem[];
  co2Reduction: number;
  workingOnAction: number;
  onClickHamburger: () => void;
  onClicktutorial: () => void;
  logout: LogoutHandler;
  onClickScoreHelp: () => void;
  onClickScoreElectricityHelp: () => void;
  onClickLookBackButton: () => void;
  onClickCategory: (category: GreenscoreActionCategory) => void;
  onClickModalClose: () => void;
  onClickDataLinkageInfo: () => void;
  modalState: ModalState;
  snackbarState: boolean;
  snackbarConfClick: () => void;
  hamburger: boolean;
  tabbarHandlers: TabbarHandlers;
  retrospectiveState: "noAction" | "doneAll" | "available";
  isCurrentScoreShowable: boolean;
  isNotLinked: boolean;
};

// 円グラフ横幅
const CIRCLE_GRAPH_WIDTH = 175;
// 円グラフ縦幅
const CIRCLE_GRAPH_HEIGHT = 175;
// 円グラフの線の太さ
const CIRCLE_GRAPH_STOROKE_WIDTH = 18;

// 日付を日付範囲の表示形式にして返す
const toRangeDate = (date: Date) =>
  `${date.getMonth() + 1}月${date.getDate()}日`;

// 前回比から対応したクラス名を返す
const getScoreDiffClassName = (diff?: number) => {
  if (typeof diff !== "number") return style.scoreDiffEven;
  if (0 < diff) {
    return style.scoreDiffUp;
  }
  if (diff < 0) {
    return style.scoreDiffDown;
  }
  return style.scoreDiffEven;
};

const message = "振り返りを行ってスコアを最新にしましょう！";

// 前回比の表示文字列を返す
const getScoreDiffValue = (diff?: number) =>
  typeof diff !== "number" ? "-" : Math.abs(diff);

const createBlankScoreItem = (
  category: GreenscoreActionCategory
): ScoreItem => ({
  category,
  value: 0,
  diff: 0,
});

export const Home: FC<Props> = (props) => {
  // 読み込み完了かどうか
  const [isLoaded, setLoaded] = useState(false);
  const [snackbar, setSnackbar] = useState(props.snackbarState);

  const {
    electricityScore,
    powerSavingScore,
    shoppingScore,
    foodScore,
    resourceScore,
    transportationScore,
    totalScore,
    graphData,
    scoreDiff,
  } = useMemo<GreenScoreHomeData>(
    () => new GreenScoreHomeData(props.score, props.prevScoreSum),
    [props.prevScoreSum, props.score]
  );

  const onClickScoreElectricityHelp = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    props.onClickScoreElectricityHelp();
    event.stopPropagation();
  };
  const [isShowTabBar, setShowTabBar] = useState(false);
  const [isNotAction, setNotAction] = useState(false);
  const [isReset, setReset] = useState(false);
  const [isFadeOut, setFadeOut] = useState("");
  const [isPlay, setPlay] = useState(false);

  // 振り返りリセット後のcss設定
  const isResetRetrospective =
    (props.modalState === "none" ||
      props.modalState === "resetRetrospective") &&
    props.isCurrentScoreShowable === false &&
    props.retrospectiveState == "available" &&
    snackbar === false;

  // モーダル表示を制御
  const showModal = (_modalState: ModalState) => {
    const _isShowTabModal = _modalState === "noActionModal";
    const _isFirstRetro = _modalState === "firstRetrospective";
    const _isReset = _modalState === "resetRetrospective";
    if (_isShowTabModal || _isFirstRetro || _isReset) {
      setPlay(true);
      setTimeout(() => {
        setShowTabBar(_isFirstRetro || _isShowTabModal);
        setNotAction(_isShowTabModal);
        setReset(_isFirstRetro || _isReset);
        setPlay(false);
      }, 1000);
    }
  };

  // モーダル閉塞を制御
  const onCloseModal = useCallback(() => {
    props.onClickModalClose();
    if (isPlay) return;
    setFadeOut(style.fadeOutAnime);
    setTimeout(() => {
      setShowTabBar(false);
      setNotAction(false);
      setReset(false);
    }, 300);
  }, [props, isPlay]);
  // 読み込み完了時にフラグを立てる
  useEffect(() => setLoaded(true), []);
  useEffect(() => showModal(props.modalState), [props.modalState]);

  const router = useRouter();
  const infoPowerLinkHandler = () => {
    openCommonApp(path.powerLinkageInfo);
  };
  const enquiryHandler = () => {
    router.push("/contactUs");
  };
  // 読み込みが完了するまでは空の要素を返す
  if (!isLoaded) {
    return <></>;
  }
  return (
    <div className={style.main}>
      <motion.div
        style={{
          position: "absolute",
          zIndex: 30,
          top: "16px",
          left: "calc(50% - 171.5px)",
          width: "343px",
          height: "100px",
          display: snackbar ? "initial" : "none",
        }}
        drag="y"
        dragConstraints={{ bottom: 0 }}
        onDrag={(event, info) => {
          if (info.point.y < 50) {
            setSnackbar(false);
          }
        }}
      >
        <div className={style.snackbar}>
          <div className={style.snackbarTexts}>
            <div>
              <span className={style.snackbarTitle}>
                {"アクションを習慣化しよう"}
              </span>
            </div>
            <div>
              <span className={style.snackbarText}>
                {"習慣になったアクションを「習慣済み」に変更しよう。"}
              </span>
            </div>
          </div>
          <div
            className={style.snackbarRight}
            onClick={props.snackbarConfClick}
          >
            <span className={style.snackbarComfirm}>{"確認"}</span>
          </div>
        </div>
      </motion.div>
      {props.hamburger && (
        <>
          <div className={style.hamburgerBack}>
            <HamburgerMenu
              profileHandler={() => router.push(path.profile.index)}
              infoPowerLinkHandler={infoPowerLinkHandler}
              introductionHandler={() => router.push(path.friendIntroductionPage)}
              tutorialHandler={props.onClicktutorial}
              isVisibleGreenStatus={false}
              faqHandler={() => window.open(path.faq, "_blank")}
              enquiryHandler={enquiryHandler}
              contractHandler={() => window.open(path.terms, "_blank")}
              privacyHandler={() => openCommonApp(path.privacyPolicy)}
              logout={props.logout}
              onClickHamburger={() => props.onClickHamburger()}
            />
          </div>
        </>
      )}
      {props.logout.logoutState && (
        <ConfirmModal
          title={labels.logoutModalTitle}
          message={labels.logoutModalMessage}
          cancelButton={{
            label: labels.logoutModalCancelButton,
            onClick: props.logout.onClickCancelLogout,
          }}
          okButton={{
            label: labels.logoutModalConfirmButton,
            onClick: props.logout.onClickLogoutHandler,
          }}
        />
      )}
      <div className={style.header}>
        <div className={style.logo}>
          <div className={style.logoImage} />
        </div>
        <div
          className={style.hamburger}
          onClick={() => props.onClickHamburger()}
        />
      </div>

      <div className={style.content}>
        <div className={style.scoreTopBlock}>
          <div className={style.scoreTitle}>
            <div className={style.scoreTitleText}>グリーンスコア</div>
            <div
              className={style.scoreTitleHelp}
              onClick={() => props.onClickScoreHelp()}
            ></div>
          </div>
          <div className={style.dateRange}>{toRangeDate(props.nowDate)}</div>
          <div className={style.graphBlockArea}>
            <div className={style.graphBlock}>
              <div className={style.graphCenterBlock}>
                {!props.isCurrentScoreShowable ? (
                  <>
                    <div className={style.totalScore}>{"---"}</div>
                    <div className={style.prevDiffBlock}>
                      <div className={style.prevDiffLabel}>振り返り前</div>
                    </div>
                    <div className={style.prevDiffValueBlock}>
                      <DiffValueBlock
                        diffValue={undefined}
                        graphdiff={true}
                        isNoLinkageElectricity={false}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={style.totalScore}>
                      <ActionScore number={totalScore} />
                    </div>
                    <div className={style.prevDiffBlock}>
                      <div className={style.prevDiffLabel}>前回振り返り：</div>
                      <div className={style.prevValue}>
                        {typeof props.prevScoreSum !== "number"
                          ? "-"
                          : `${props.prevScoreSum}`}
                      </div>
                    </div>
                    <div className={style.prevDiffValueBlock}>
                      <DiffValueBlock
                        diffValue={scoreDiff}
                        graphdiff={true}
                        isNoLinkageElectricity={false}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={style.circleGraph(CIRCLE_GRAPH_WIDTH)}>
                <CircleGraph
                  width={CIRCLE_GRAPH_WIDTH}
                  height={CIRCLE_GRAPH_HEIGHT}
                  strokeWidth={CIRCLE_GRAPH_STOROKE_WIDTH}
                  data={graphData}
                />
                {/* {props.showingMessage && (
                <div className={style.messageBalloon}>{message}</div>
              )} */}
              </div>
            </div>
          </div>
        </div>
        <div className={style.scoreBottomBlock}>
          <div className={style.scoreBottomBlockRow}>
            <ScoreBottomBlock
              onClick={props.onClickCategory}
              color={SHOPPING_COLOR}
              scoreItem={shoppingScore}
              key={shoppingScore.category}
            />
            <ScoreBottomBlock
              onClick={props.onClickCategory}
              color={FOOD_COLOR}
              scoreItem={foodScore}
              key={foodScore.category}
            />
          </div>
          <div className={style.scoreBottomBlockRow}>
            <ScoreBottomBlock
              onClick={props.onClickCategory}
              color={TRANSPORTATION_COLOR}
              scoreItem={transportationScore}
              key={transportationScore.category}
            />
            <ScoreBottomBlock
              onClick={props.onClickCategory}
              color={RESOURCE_COLOR}
              scoreItem={resourceScore}
              key={resourceScore.category}
            />
          </div>
          <div className={style.scoreBottomBlockRow}>
            <ScoreBottomBlock
              onClick={props.onClickCategory}
              color={POWERSAVING_COLOR}
              scoreItem={powerSavingScore}
              key={powerSavingScore.category}
            />
            <div
              className={style.scoreBottommostItem}
              onClick={() => {
                props.onClickCategory(electricityScore.category);
              }}
            >
              <div className={style.scoreColorSample(ELECTRICITY_COLOR)}></div>
              <div className={style.scoreLabel}>
                {electricityScore.category}
              </div>
              <div
                className={style.scoreElectricityHelp}
                onClick={onClickScoreElectricityHelp}
              ></div>
              <div className={style.scoreValue}>
                <span className={style.scoreValueText}>
                  {electricityScore.value}
                </span>
                <DiffValueBlock
                  diffValue={electricityScore.diff}
                  graphdiff={false}
                  isNoLinkageElectricity={props.isNotLinked}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.co2ReductionCard}>
          <div className={style.co2ReductionBlock}>
            <div className={style.co2ReductionLabel}>累計削減量</div>
            <div className={style.co2ReductionAmount}>
              {props.co2Reduction !== 0 ? props.co2Reduction : "-"}
              <span className={style.co2ReductionAmountUnit}>kg-CO2</span>
            </div>
          </div>
        </div>
      </div>
      <div className={style.workingOnActionCardBackground}>
        <div className={style.workingOnActionCard}>
          {props.modalState != "resetRetrospective" &&
            props.retrospectiveState == "available" && (
              <div
                className={style.lookBackButton}
                onClick={() => props.onClickLookBackButton()}
              >
                アクション振り返り
              </div>
            )}
          {props.retrospectiveState == "noAction" && (
            <p className={style.noActionMessage}>
              設定したアクションがありません。
            </p>
          )}
          {props.retrospectiveState == "doneAll" && (
            <p className={style.doneAllActionMessage}>
              全てのアクションが習慣済みです。
            </p>
          )}
        </div>
      </div>
      <Tabbar
        activeTab="ホーム"
        onClick={{
          home: props.tabbarHandlers.home,
          action: props.tabbarHandlers.action,
          mission: props.tabbarHandlers.mission,
          electricity: props.tabbarHandlers.electricity,
        }}
      />
      {isShowTabBar && (
        <div className={!isFadeOut ? style.modalBase : isFadeOut}></div>
      )}
      {isNotAction && (
        <div className={isFadeOut}>
          <TabbarActionHighlightLayer
            onClickClose={() => {
              onCloseModal();
            }}
          />
        </div>
      )}
      {isReset && (
        <div className={isFadeOut}>
          <WorkingOnActionCardOverModal
            onClickClose={() => {
              onCloseModal();
            }}
            onClickLookBackButton={props.onClickLookBackButton}
            isResetRetrospective={isResetRetrospective}
          />
        </div>
      )}
      {props.modalState == "ngAfterDataLinkage" && (
        <LinkageAfterFailModal
          isHome={true}
          onButtonClick={props.onClickDataLinkageInfo}
          onCloseClick={props.onClickModalClose}
          onGoHome={() => {
            console.log("onGoHome");
          }}
        />
      )}
    </div>
  );
};

const DiffValueBlock: FC<{
  diffValue: number | undefined;
  graphdiff: boolean;
  isNoLinkageElectricity: boolean;
}> = ({ diffValue, graphdiff, isNoLinkageElectricity }) => (
  <div className={style.scoreDiffFrame(graphdiff, isNoLinkageElectricity)}>
    <div className={style.scoreDiffLine(graphdiff)}>
      {isNoLinkageElectricity === true ? (
        <span className={style.notLinked}>{"( 未連携 )"}</span>
      ) : (
        <div>
          {typeof diffValue !== "number" || diffValue === 0 ? (
            <img
              alt="score even"
              src="/img/templates/home/scoreBottom/even.svg"
              className={style.scoreDiffArrow}
            />
          ) : diffValue > 0 ? (
            <img
              alt="score up"
              src="/img/templates/home/scoreBottom/up.svg"
              className={style.scoreDiffArrow}
            />
          ) : (
            <img
              alt="score down"
              src="/img/templates/home/scoreBottom/down.svg"
              className={style.scoreDiffArrow}
            />
          )}
          <span className={style.scoreDiff}>
            {getScoreDiffValue(diffValue)}
          </span>
        </div>
      )}
    </div>
  </div>
);

const ScoreBottomBlock: FC<{
  onClick: (category: GreenscoreActionCategory) => void;
  color: string;
  scoreItem: ScoreItem;
}> = ({ onClick, color, scoreItem }) => (
  <div
    className={
      scoreItem.category === "節電"
        ? style.scoreBottommostItem
        : style.scoreBottomItem
    }
    onClick={() => {
      onClick(scoreItem.category);
    }}
  >
    <div className={style.scoreColorSample(color)}></div>
    <div className={style.scoreLabel}>{scoreItem.category}</div>
    <div className={style.scoreValue}>
      <span className={style.scoreValueText}>
        {typeof scoreItem.value !== "number" ? "-" : "" + scoreItem.value}
      </span>
      <DiffValueBlock
        diffValue={scoreItem.diff}
        graphdiff={false}
        isNoLinkageElectricity={false}
      />
    </div>
  </div>
);

const WorkingOnActionCardOverModal: FC<{
  onClickClose: () => void;
  onClickLookBackButton: () => void;
  isResetRetrospective: boolean;
}> = ({ onClickClose, onClickLookBackButton, isResetRetrospective }) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => onClickClose());
  return (
    <div className={style.workingOnActionCardOverModal}>
      <div className={style.tooltipOverModal}>
        <div className={style.tooltipText} ref={ref}>
          スコアが反映されていません。 一週間の振り返りをしよう！
        </div>
        <div
          className={style.tooltipClosebutton}
          onClick={() => {
            onClickClose();
          }}
        ></div>
      </div>
      <div
        className={style.lookBackButtonOverModalBorder(isResetRetrospective)}
      >
        <div
          className={style.lookBackButtonOverModal}
          onClick={() => onClickLookBackButton()}
        >
          アクション振り返り
        </div>
      </div>
    </div>
  );
};

const SHOPPING_COLOR = "#E6FFFB";
const FOOD_COLOR = "#B7E1DA";
const TRANSPORTATION_COLOR = "#4DBDA8";
const RESOURCE_COLOR = "#359D88";
const POWERSAVING_COLOR = "#2C7F6C";
const ELECTRICITY_COLOR = "#1E5343";
const Total_SCORE_ZERO_COLOR = "#FFFFFF40";
class GreenScoreHomeData {
  private _electricityScore: ScoreItem;
  private _powerSavingScore: ScoreItem;
  private _shoppingScore: ScoreItem;
  private _foodScore: ScoreItem;
  private _resourceScore: ScoreItem;
  private _transportationScore: ScoreItem;
  private _totalScore: number;
  private _scoreDiff?: number;
  constructor(scoreItems: ScoreItem[], prevScoreSum?: number) {
    this._electricityScore =
      scoreItems.find((item) => item.category == "電気") ||
      createBlankScoreItem("電気");
    this._powerSavingScore =
      scoreItems.find((item) => item.category == "節電") ||
      createBlankScoreItem("節電");
    this._shoppingScore =
      scoreItems.find((item) => item.category == "買い物") ||
      createBlankScoreItem("買い物");
    this._foodScore =
      scoreItems.find((item) => item.category == "食") ||
      createBlankScoreItem("食");
    this._resourceScore =
      scoreItems.find((item) => item.category == "資源") ||
      createBlankScoreItem("資源");
    this._transportationScore =
      scoreItems.find((item) => item.category == "移動") ||
      createBlankScoreItem("移動");
    this._totalScore =
      (scoreItems[0].value || 0) +
      (scoreItems[1].value || 0) +
      (scoreItems[2].value || 0) +
      (scoreItems[3].value || 0) +
      (scoreItems[4].value || 0) +
      (scoreItems[5].value || 0);
    this._scoreDiff = prevScoreSum
      ? this._totalScore - prevScoreSum
      : undefined;
  }

  get electricityScore(): ScoreItem {
    return this._electricityScore;
  }

  get powerSavingScore(): ScoreItem {
    return this._powerSavingScore;
  }

  get shoppingScore(): ScoreItem {
    return this._shoppingScore;
  }

  get foodScore(): ScoreItem {
    return this._foodScore;
  }

  get resourceScore(): ScoreItem {
    return this._resourceScore;
  }

  get transportationScore(): ScoreItem {
    return this._transportationScore;
  }

  get totalScore(): number {
    return this._totalScore;
  }

  get scoreDiff(): number | undefined {
    return this._scoreDiff;
  }
  get graphData(): Data[] {
    let returnGraphData: Data[] = [];
    if (this.totalScore === 0) {
      returnGraphData[returnGraphData.length] = {
        color: Total_SCORE_ZERO_COLOR,
        value: 0,
        category: "スコア0",
      };
    } else {
      if (this._shoppingScore.value) {
        returnGraphData[returnGraphData.length] = {
          color: SHOPPING_COLOR,
          value: this._shoppingScore.value || 0,
          category: "買い物",
        };
      }
      if (this._foodScore.value) {
        returnGraphData[returnGraphData.length] = {
          color: FOOD_COLOR,
          value: this._foodScore.value || 0,
          category: "食",
        };
      }
      if (this.transportationScore.value) {
        returnGraphData[returnGraphData.length] = {
          color: TRANSPORTATION_COLOR,
          value: this.transportationScore.value || 0,
          category: "移動",
        };
      }
      if (this._resourceScore.value) {
        returnGraphData[returnGraphData.length] = {
          color: RESOURCE_COLOR,
          value: this._resourceScore.value || 0,
          category: "資源",
        };
      }
      if (this._powerSavingScore.value) {
        returnGraphData[returnGraphData.length] = {
          color: POWERSAVING_COLOR,
          value: this._powerSavingScore.value || 0,
          category: "節電",
        };
      }
      if (this._electricityScore.value) {
        returnGraphData[returnGraphData.length] = {
          color: ELECTRICITY_COLOR,
          value: this._electricityScore.value || 0,
          category: "電気",
        };
      }
    }
    return returnGraphData;
  }
}
