import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { button, PopupFooter } from "../../atoms/PopupFooter";
import { PopupHeader } from "../../atoms/PopupHeader";

interface Props {
  message: string;
  text?: string;
  cancel?: button;
  proceed: button;
}

export const PopupBody: FC<Props> = (props) => {
  return (
    <div className={divStyle}>
      <PopupHeader message={props.message} text={props.text}></PopupHeader>
      <PopupFooter cancel={props.cancel} proceed={props.proceed} />
    </div>
  );
};

const divStyle = css`
  width: 283px;
  height: 192px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 0px rgba(30, 83, 67, 0.1);
  background-color: #fff;
`;
