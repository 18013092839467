import { css } from "@emotion/css";

export const button = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 283px;
  height: 44px;

  text-align: center;
  border-radius: 22px;
  /* top: 22px;
  bottom: 24px; */
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: none;
  background: linear-gradient(90deg, #1e5343 0%, #359d88 100%);
  cursor: pointer;

  &[disabled] {
    background: #e1e1e1;
  }
`;

export const buttonWhite = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 283px;
  height: 44px;

  text-align: center;
  border-radius: 22px;
  /* top: 22px;
  bottom: 24px; */
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #1e5343;
  border: 1px solid #1e5343;
  background-color: #f7f7f7;
  cursor: pointer;

  &[disabled] {
    border: 1px solid #e1e1e1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #b3b3b3;
  }
`;

export const buttonParents = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 283px;
  height: 44px;

  text-align: center;
  border-radius: 22px;
  /* top: 22px;
  bottom: 24px; */
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #1e5343;
  border: 1px solid #1e5343;
  background-color: transparent;
  cursor: pointer;
`;
