import { useSelector } from "react-redux";
import { RootState } from "../store";
import { GreenscoreState, ActionListState, ActionTopState } from "./slice";

export const useGetActionListState_add = () => {
  return useSelector<RootState, ActionListState>(
    (state) => state.greenscoreAction.actionList_add
  );
};

export const useGetActionListState_habitual = () => {
  return useSelector<RootState, ActionListState>(
    (state) => state.greenscoreAction.actionList_habitual
  );
};

export const useGetActionTopState = () => {
  return useSelector<RootState, ActionTopState>(
    (state) => state.greenscoreAction.actionTop
  );
};

export const useGetIsDoneActionReview = () => {
  return useSelector<RootState, boolean>(
    (state) => state.greenscoreAction.isDoneActionReview
  );
};

export const useIsDoneTopPage = () => {
  return useSelector<RootState, boolean>(
    (state) => state.greenscoreAction.isDoneTopPage
  );
};
